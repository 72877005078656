import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// import qs from "qs";

export const getFasilitator = () => async(dispatch) =>{
    try {
        await axios.get(`${process.env.REACT_APP_URL}fasilitator/get_fasilitator`)
        .then((res)=>{
            dispatch(setFasilitator(res.data));
        }).catch((err)=>console.log(err));
    } catch (error) {
        console.log(error);
    }
}

export const getFasilitatorSingle = (id_fasilitator) =>async(dispatch)=>{
    try {
        await axios.get(`${process.env.REACT_APP_URL}fasilitator/get_fasilitator`)
        .then((res)=>{
            let filData = res.data.find(row=>parseInt(row.id_fasilitator) === parseInt(id_fasilitator));
            dispatch(setFasSingle(filData));

        })
        .catch(error=>console.log(error))
    } catch (error) {
        console.log(error);
    }
}

const Fasilitator = createSlice({
    name:'fasilitator',
    initialState:{
        fasilitator:[],
        fasSingle:[]
    },
    reducers:{
        setFasilitator(state,actions){
            state.fasilitator = actions.payload
        },
        setFasSingle(state,actions){
            state.fasSingle = actions.payload
        }
    }
});

const {setFasilitator,setFasSingle} = Fasilitator.actions;

export default Fasilitator;