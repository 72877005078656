import {useState,useRef } from 'react';
import { loginState, register } from '../store/Member';
import { useDispatch } from 'react-redux';
import { useNavigate,Link } from 'react-router-dom';


// const title = "Register Now";
const btnText = "Get Started Now";

const Register = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [input,setInput] = useState({nama:'',email:'',instansi:'',telepon:'',password:''});
    const [showPass,setShowPass] = useState(false);
    const [passCek,setPassCek] = useState(false);
    const [loading,setLoading] = useState(false);

    const conPass = useRef('');

    const submitReg = async(e)=>{
        e.preventDefault();
        setLoading(true);
        if(input.password === conPass.current.value){

            await dispatch(register(input)).then(async()=>{
                await setLoading(false);
                await localStorage.setItem('isLogin',true);
                await localStorage.setItem('email',input.email);
                await dispatch(loginState(true));
            }).catch((e)=>{
                console.log(e);
            }).finally(()=>{
                navigate('/Ourclass/MostFav');
            });

        }else{
            
            setLoading(false);
            setPassCek(true);
        }
    }

  return (
    <>
        <div className="login-section padding-tb section-bg">
                <div className="container">
                    {passCek && (<div className="alert alert-danger" role="alert">
                        Password Tidak Sama
                    </div>)}
                    <div className="account-wrapper">
                        <div className="logo">
                            <Link to="/"><img src={require("../assets/images/orbit.png" )} style={{ width: '200px', height: '50px' }} className="mt-1 mb-1" alt="logo" /></Link>
                        </div>
                        <div style={{border:"1px solid grey",margin:"10px 0"}}>
                            
                        </div>
                        <h3 className="title">
                                <span style={{ color: "#ff8000" }}>REGISTER </span>
                                <span style={{ color: "#0080ff" }}>NOW</span>
                            </h3>
                        <form className="account-form" onSubmit={submitReg}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="nama"
                                    placeholder="Name"
                                    onChange={(e)=>setInput({...input,nama:e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    onChange={(e)=>setInput({...input,email:e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="instansi"
                                    placeholder="Instansi"
                                    onChange={(e)=>setInput({...input,instansi:e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="telepon"
                                    placeholder="Telepon"
                                    onChange={(e)=>setInput({...input,telepon:e.target.value})}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type={showPass ?"text":"password"}
                                    name="password"
                                    placeholder="Password"
                                    onChange={(e)=>setInput({...input,password:e.target.value})}
                                    required
                                />
                                <span className="input-icon">
                                    <i className={showPass ?"icofont-eye":"icofont-eye-blocked"} onClick={()=>setShowPass(!showPass)}></i>
                                </span>
                            </div>
                            <div className="form-group">
                                <input
                                    type={showPass ?"text":"password"}
                                    name="password"
                                    placeholder="Confirm Password"
                                    ref={conPass}
                                />
                                <span className="input-icon">
                                    <i className={showPass ?"icofont-eye":"icofont-eye-blocked"} onClick={()=>setShowPass(!showPass)}></i>
                                </span>
                            </div>
                            <div className="form-group">
                                <div className="d-flex justify-content-end flex-wrap pt-sm-2">
                                    <Link to="/Login">Login Here</Link>
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="loading-container" disabled={loading?true:false}>{ loading ? <div className="loading-button"></div> : <span>{btnText}</span>} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Register