import React, { useEffect } from 'react'
import {Link} from 'react-router-dom';
import { getProduk } from '../../store/Produk';
import { useDispatch,useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import { AES } from 'crypto-js';
// import Modaldata from '../../components/Modaldata';

const Class = () => {
    const dispatch = useDispatch();
    const dataProduk = useSelector(state => state.Produk.produk);
    // const [showModal,setShowModal] =useState(false);
    // const dataFil = dataProduk.filter(row => row.jenis === params.jenis );
    
    useEffect(()=>{
        dispatch(getProduk());
    },[dispatch]);

    const clickItemHandler = () =>{
        //setShowModal(true);
     } 
    
    return (
      <>
        {/* { showModal !== false ? <Modaldata /> : '' }  */}
        <div className="course-section padding-tb section-bg">
          <div className="container">
            <div className="section-header text-center">
              <h2 className="title">
                <span style={{ color: "#ff8000" }}>PUBLIC</span>
                <span style={{ color: "#0080ff" }}> CLASS</span>
              </h2>
            </div>
            <div className="section-wrapper">
              <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
                {dataProduk.map((row, index) => (
                  <div className="col" key={index}>
                    <div className="course-item" onClick={clickItemHandler}>
                      <div className="course-inner">
                        <div className="course-thumb">
                          <img
                            src={`${
                              process.env.REACT_APP_URL +
                              "assets/foto/" +
                              row.foto
                            }`}
                            alt={`${row.foto}`}
                            style={{
                              objectFit: "contain",
                              height: "auto",
                              minHeight: "280px",
                            }}
                          />
                        </div>
                        <div className="course-content">
                          <div
                            className="course-price"
                            style={{ minWidth: "25vh", borderRadius: "10px" }}
                          >
                            <i className="icofont-calendar"></i>
                            {row.jenis !== ""
                              ? new Date(
                                  JSON.parse(row.tgl_program)[0]
                                ).toLocaleDateString("id-ID", {
                                  day: "numeric",
                                }) +
                                " - " +
                                new Date(
                                  JSON.parse(row.tgl_program)[1]
                                ).toLocaleDateString("id-ID", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                })
                              : "Upon Request"}
                          </div>
                          <div className="course-category">
                            <div
                              style={{
                                backgroundColor: "grey",
                                padding: "5px",
                                marginBottom: "12px",
                                borderRadius: "4px",
                                color: "#ffffff",
                              }}
                            >
                              {row.kategori}
                            </div>
                          </div>
                          <Link
                            to={
                              row.jenis !== ""
                                ? `/Ourclass/ClassDetail/${encodeURIComponent(
                                    AES.encrypt(
                                      row?.id_produk.toString(),
                                      "goorbit"
                                    ).toString()
                                  )}`
                                : ""
                            }
                          >
                            <h4>{row.title}</h4>
                          </Link>
                          <div className="course-details">
                            {row.jenis !== "" ? (
                              <div className="couse-count">
                                <i className="icofont-people"></i>
                                {row.jumlah_peserta + ` Peserta`}
                              </div>
                            ) : (
                              <div className="couse-count">
                                {row.deskripsi_program}
                              </div>
                            )}
                          </div>
                          {row.jenis !== "" ? (
                            <div className="course-footer">
                              <div className="course-btn">
                                <Link
                                  to={`/Ourclass/ClassDetail/${encodeURIComponent(
                                    AES.encrypt(
                                      row?.id_produk.toString(),
                                      "goorbit"
                                    ).toString()
                                  )}`}
                                  className="lab-btn-text"
                                >
                                  {`ORDER NOW`}{" "}
                                  <i className="icofont-external-link"></i>
                                </Link>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <hr className="divider" />
            </div>
          </div>
        </div>
      </>
    );
}

export default Class