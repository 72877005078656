import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { getAbout } from '../../store/Produk';

const Story = () => {

  const dispatch = useDispatch();
  // const params = useParams();
  const [about,setAbout] = useState(
                                  {
                                  deskripsi:'',
                                  foto:''
                                 }
                              ); 
  const aboutData = useSelector(state=>state.Produk?.about);

    useEffect(() => {
          const fetchData = async () => {
                    await dispatch(getAbout());
                    if(aboutData.length !== 0)
                            await setAbout(...aboutData);
                        };
                        fetchData();
          }, [dispatch,aboutData]);

  return (
    <>
        <div className="container">
        {/* <div className="section-header text-center">
            <span className="subtitle" sx={{color: '#3393FF'}}>{}</span>
            <h2 className="title" sx={{color: '#3393FF'}}>Our Story</h2>
        </div> */}
        <div className="row mt-4">
            <div className="col-lg-12">
            <div dangerouslySetInnerHTML={{__html:about?.deskripsi}} ></div>

              <div className="row">
                  <div className="col-lg-12 mt-3 mb-3" style={{ display:'flex',justifyContent:'center',alignItems: 'center'}}>
                      <img src={process.env.REACT_APP_URL+`assets/foto/${about.foto}`} style={{height:"40vh"}} alt="story" />
                  </div>
              </div>

            </div>
        </div>
        </div>
    </>
  )
}

export default Story