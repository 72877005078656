import React from 'react'
import PageHeader from '../../components/Pageheader'

const Case = () => {
  return (
    <>
        <PageHeader title="CASE STUDY" />
    </>
  )
}

export default Case