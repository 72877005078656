import { configureStore } from "@reduxjs/toolkit";
import Guest from "./Guest";
import Produk from "./Produk";
import Member from "./Member";
import Fasilitator from "./Fasilitator";

const store = configureStore({
    reducer:{
        Guest:Guest.reducer,
        Produk:Produk.reducer,
        Member:Member.reducer,
        Fasilitator:Fasilitator.reducer,
    }
});

export default store;