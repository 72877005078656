import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import { loginMember,loginState } from '../store/Member';

const title = "Login";
const btnText = "Submit Now";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [user,setUser] = useState({email:'',password:''});
    const [showPass,setShowPass] = useState(false);
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(false);
    
    const loginSubmit = async(e) =>{
        e.preventDefault();
        setLoading(true);
        await dispatch(loginMember(user))
        .then(async(data)=>{
            setLoading(false);
            if(data.email){
                await localStorage.setItem('isLogin',true);
                await localStorage.setItem('email',data.email);
                dispatch(loginState(true));
                navigate('/ourClass/MostFav')
            }else if(!data.email || !data.password){ 
                setError(true);
            }
        })
    }

  return (
    <>
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    {error && (<div className="alert alert-danger" role="alert">
                            Email / Password Tidak ditemukan
                    </div>)}
                    <div className="account-wrapper">
                        <div className="logo">
                            <Link to="/"><img src={require("../assets/images/orbit.png" )} style={{ width: '200px', height: '50px' }} className="mt-1 mb-1" alt="logo" /></Link>
                        </div>
                        <div style={{border:"1px solid grey",margin:"10px 0"}}></div>
                        <h3 className="title">{title}</h3>
                        <form onSubmit={loginSubmit} className="account-form">
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    onChange={(e)=>setUser({...user,email:e.target.value})}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type={showPass ? "text":"password"}
                                    name="password"
                                    placeholder="Password *"
                                    onChange={(e)=>setUser({...user,password:e.target.value})}
                                />
                                <span className="input-icon">
                                    <i className={showPass ?"icofont-eye":"icofont-eye-blocked"} onClick={()=>setShowPass(!showPass)}></i>
                                </span>
                            </div>
                            <div className="form-group">
                                {/* <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                    <div className="checkgroup">
                                        <input type="checkbox" name="remember" id="remember" />
                                        <label htmlFor="remember">Remember Me</label>
                                    </div>
                                    <Link to="/forgetpass">Forget Password?</Link>
                                </div> */}
                                <div className="d-flex justify-content-end flex-wrap pt-sm-2">
                                    <Link to="/Register">Register Here</Link>
                                </div>
                            </div>
                            <div className="form-group text-center">
                            <button className="loading-container" disabled={loading?true:false}>{ loading ? <div className="loading-button"></div> : <span>{btnText}</span>} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Login