import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import qs from "qs";

export const register = (data) => async(dispatch) => {
        await axios.post(process.env.REACT_APP_URL+`member/register_member`,JSON.stringify(data),{headers:{'Content-Type': 'application/json'}});
}

export const loginState = (data) => async(dispatch)=>{
        return new Promise((resolve,reject) =>{
            dispatch(setLogin(data));
            resolve();
        });
}

export const loginMember = (data)=>async(dispatch)=>{
    // console.log(data)
        const res = await axios.post(process.env.REACT_APP_URL+`member/login_member`,qs.stringify(data));
        return res.data
}

export const createSignIn = (data) => async(dispatch)=>{
    await axios.post(process.env.REACT_APP_URL+`member/create_signIn`,qs.stringify(data));
    return;
}

const Member = createSlice({
    name:'member',
    initialState:{
        isLoggedIn:false
    },
    reducers:{
        setLogin(state,action){
            state.isLoggedIn = action.payload;
        }
    }
})

const {setLogin} = Member.actions; 


export default Member;