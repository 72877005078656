// import HTMLReactParser from 'html-react-parser';
import React,{useState,useEffect} from 'react'

// const title = "Judul ";
// const desc = "Deskripsi";
// const author = "Nama Talent";
// const reviewCount = "03 reviews";
// const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";


// const categoryList = [
//     {
//         link: '#',
//         text: 'Adobe XD',
//         className: 'course-cate',
//     },
//     {
//         link: '#',
//         text: '30% Off',
//         className: 'course-offer',
//     },
// ]

const HeaderServices = (props) => {
    const [isMobile,setIsMobile] = useState(window.innerWidth <= 767);
    

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 767);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      const firstStyle = {
        padding:'6.25rem'
    }

      const mobileSize = isMobile ? {padding:'-0.75rem'} : {};
  return (
    <div className="pageheader-section style-2" style={{...firstStyle,...mobileSize}} >
        <div className="container">
            <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                <div className="col-lg-7 col-12">
                    <div className="pageheader-thumb">
                        <img src={`${process.env.REACT_APP_URL}assets/foto_layanan/${props.data?.foto_layanan}`} alt="props.data.foto_layanan" className="w-100" />
                    </div>
                </div>
                <div className="col-lg-5 col-12">
                    <div className="pageheader-content">
                        <div className="course-category">
                            <span className="course-cate" >{props.data?.kode?.toUpperCase()}</span>
                        </div>
                        <h2 className="phs-title">{props.data?.judul}</h2>
                        <div className="phs-desc">
                            <div dangerouslySetInnerHTML={{__html:props.data?.deskripsi}} />
                        </div>
                        <div className="phs-thumb">
                            <img src={`${process.env.REACT_APP_URL}assets/foto_fas/${props.data?.fasilitator.foto}`} alt={props.data?.foto_layanan} />
                            <span>{props.data?.fasilitator.nama}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HeaderServices