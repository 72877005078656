import React from 'react'
// import Modaldata from '../../components/Modaldata';
import { createGuestProspek } from '../../store/Guest';
import { useDispatch } from 'react-redux';

const Inhouse = () => {
    const dispatch = useDispatch();
    const guestConsult = async()=>{
        await dispatch(createGuestProspek({email:localStorage.getItem('email_user')}));
        await window.open('https://api.whatsapp.com/send?phone=628115184818','_blank');
        
      }    
  return (
    <>
        {/* <Modaldata /> */}
        <div className="course-section padding-tb section-bg">
            <div className="container">
            <div className="section-header text-center">
                <h2 className="title"><span style={{color:'#ff8000'}} >IH HOUSE</span><span style={{color:'#0080ff'}}> CLASS</span></h2>
            </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center align-items-center g-2 py-5">
                      <div className="col-md-12 d-flex justify-content-center mb-10">
                          <div className="card card-inhouse">
                          <div className="card-body">'
                              {/* <h4 class="card-title">In </h4> */}
                              <p className="card-text" >DISKUSI & LAKUKAN TRAINING NEED ANALISIS DENGAN KAMI</p>
                              <button className="btn btn-primary btn-block"  onClick={guestConsult} ><i className="icofont-ui-call"></i> DISCUSS HERE</button>
                          </div>
                              <img className="card-img-top" src={require('../../assets/images/Discussion.gif')} style={{width: '50%', height: '70%'}} alt="Title" />
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Inhouse