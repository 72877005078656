import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Rating from './component/Rating';
// import PageHeader from "../../components/Pageheader";
import { useDispatch,useSelector } from "react-redux";
import { getFasilitator } from "../../store/Fasilitator";

// const instructorTopList = [
//     {
//         imgUrl: require('../../assets/ojik.png'),
//         imgAlt: 'FITRIYAN ROZI, MM. LCPC',
//         name: 'FITRIYAN ROZI, MM. LCPC',
//         degi: 'CEO, COACH MANAGER, TRAINER AND CONSULTANT',
//         infoList: [
//             {
//                 iconName: 'icofont-ui-check',
//                 text: 'More 18 Years Professional Working Experiences',
//             },
//             {
//                 iconName: 'icofont-ui-check',
//                 text: 'Certified Coach ICF',
//             },
//             {
//                 iconName: 'icofont-ui-check',
//                 text: 'Certified Trainer BNSP',
//             },
//             {
//                 iconName: 'icofont-ui-check',
//                 text: 'Certified Business and Organization Development Consultant',
//             },
//             {
//                 iconName: 'icofont-ui-check',
//                 text: 'Leadership and Business Management Expert',
//             },
//         ],
//         socialList: [
//             {
//                 iconName: 'icofont-facebook',
//                 className: 'facebook',
//                 siteLink: '#',
//             },
//             {
//                 iconName: 'icofont-twitter',
//                 className: 'twitter',
//                 siteLink: '#',
//             },
//             {
//                 iconName: 'icofont-linkedin',
//                 className: 'linkedin',
//                 siteLink: '#',
//             },
//         ],
//     },
// ]




const Facilitator = () => {

    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(getFasilitator());
    },[dispatch])

    const fasData = useSelector(state=>state.Fasilitator.fasilitator);


    return (
        <Fragment>
            {/* <PageHeader title={"Orbit Facilitator"} /> */}
            <div className="instructor-section padding-tb section-bg">
                <div className="container">
                    <div className="section-header text-center">
                        <h2 className="title" ><span style={{color:'#ff8000'}} >GO ORBIT</span><span style={{color:'#0080ff'}}> FASILITATOR </span></h2>
                    </div>


                    {/* <div className="section-wrapper">
                        <div className="instructor-top">
                            <div className="row g-4 justify-content-center row-cols-1 row-cols-lg-2 mb-5">
                                {instructorTopList.map((val, i) => (
                                    <div className="col" key={i}>
                                        <div className="instructor-item style-2">
                                            <div className="instructor-inner">
                                                <div className="instructor-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="instructor-content">
                                                    <Link to="/team-single"><h4>{val.name}</h4></Link>
                                                    <span className="d-block">{val.degi}</span>
                                                    <ul className="lab-ul ins-info">
                                                        {val.infoList.map((val, i) => (
                                                            <li key={i}><i className={val.iconName}></i> {val.text}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}



                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
                            {fasData.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="instructor-item">
                                        <div className="instructor-inner">
                                            <div className="instructor-thumb">
                                                <img src={`${process.env.REACT_APP_URL+'assets/foto_fas/'+val.foto}`} alt={`${val.foto}`} />
                                            </div>
                                            <div className="instructor-content">
                                                <Link to={`/About/Facilitator_single/${val.id_fasilitator}`}><h4>{val.nama}</h4></Link>
                                                <p>{val.spesialisasi}</p>
                                                <Rating />
                                            </div>
                                        </div>
                                        {/* <div className="instructor-footer">
                                            <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                                                <li><i className="icofont-book-alt"></i> {val.courseCount}</li>
                                                <li><i className="icofont-users-alt-3"></i> {val.studentAnroll}</li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                        

                    </div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default Facilitator;