
import { Link } from 'react-router-dom';
const featureList = [
    {
        imgUrl: require('../assets/icon/icon1.png'),
        imgAlt: 'Insight sharing and Motivation',
        title: 'Insight sharing and Motivation',
        desc: 'Program motivasi dan berbagi inspirasi untuk Tim Anda dalam  tema dan materi terkini sesuai kebutuhan saat ini.',
    },
    {
        imgUrl: require('../assets/icon/icon4.png'),
        imgAlt: 'Coaching and Training',
        title: 'Coaching and Training',
        desc: 'Program pemberdayaan personal serta pengembangan TIM melalui pelatihan yang implementatif dari analisis kebutuhan institusi..',
    },
    {
        imgUrl: require('../assets/icon/icon3.png'),
        imgAlt: 'Consulting and Mentoring ',
        title: 'Consulting and Mentoring ',
        desc: 'Peningkatan dan transformasi ilmu dan keterampilan seluruh TIm internal atau eksternal oleh mentor dan konsultan terbaik.',
    },
    {
        imgUrl: require('../assets/icon/icon2.jpg'),
        imgAlt: 'Experiential learning and Outbound',
        title: 'Experiential learning and Outbound',
        desc: 'Pembelajaran dan pelatihan yang fokus pengalaman dan permainan bermakna seperti outbound dan Board Games Learning.',
    },
    {
        imgUrl: require('../assets/icon/icon5.png'),
        imgAlt: 'Pension Readiness Program',
        title: 'Pension Readiness Program',
        desc: 'Program lengkap komprehensif dengan materi, praktek, pada aspek kesehatan, keuangan dan pengelolaan keuangan pensiun.',
    },
    {
        imgUrl: require('../assets/icon/icon6.png'),
        imgAlt: 'Event and MICE Organizer',
        title: 'Event and MICE Organizer',
        desc: 'Perencanaan dan pelaksanaan konten dan kegiatan pelatihan serta meeting dalam kemasan yang kreatif dan profesional.',
    },
];

const WhatWeDo = () => {
  return (
    <div className="blog-section padding-tb">
    <div className="container">
            <div className="section-header text-center">
                <h2 className="title"><span style={{color:'#ff8000'}} >WHAT WE</span><span style={{color:'#0080ff'}}> DO ?</span></h2>
            </div>
            <div className="row g-4 justify-content-center">
                    <div className="col-lg-12 col-12">
                        <div className="feature-items">
                            <div className="row g-4 row-cols-sm-3 row-cols-1">
                                {featureList.map((val, i) => (
                                    <div className="col" key={i}>
                                        <Link to="/Ourclass/MostFav">
                                            <div className="feature-item" >
                                                <div className="feature-inner-wwd text-center" style={{justifyContent:'center',border:'2px solid #ff8000'}}>
                                                    <div className="feature-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} style={{height:'120px'}}  />
                                                    </div>
                                                    <div className="feature-content">
                                                        <h4>{val.title}</h4>
                                                        <p>{val.desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>

  )
}

export default WhatWeDo