import React from 'react'

import whatsappIcon from '../assets/images/whatsapp.png';

const FloatingBtn = () => {
    const handleClick = () =>{

    }
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: '9999',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <a
        href="https://api.whatsapp.com/send?phone=628115184818"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={whatsappIcon}
          alt="WhatsApp"
          style={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.25)',
            cursor: 'pointer',
          }}
          onClick={handleClick()}
        /><br/>
        <span
          style={{
            marginTop: '5px',
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          Call Center
        </span>
      </a>
    </div>
  )
}

export default FloatingBtn