import React,{useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { createGuest } from '../store/Guest';

const Modaldata = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const [data,setData] = useState({nama:'',email:'',telepon:''});

    const handleClose = () => setShowModal(false);

    // const handleShow = () => setShowModal(true);
    const sendData = async (e)=>{
        e.preventDefault();
        await dispatch(createGuest(data));
        await handleClose();
        await localStorage.setItem('regFree',true);
        await localStorage.setItem('email_user',data.email);
    }

    useEffect(() => {
      if (!localStorage.getItem('regFree') || localStorage.getItem('regFree') !== "true") {
        setShowModal(true);
      }
    }, []);
    
  return (
    <>
        {/* <button type="button" className="btn btn-primary" onClick={handleShow}>
        Buka Modal
      </button> */}
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mx-auto"><span style={{color:'#ff8000'}} >PLEASE ENTER </span><span style={{color:'#0080ff'}}> THE GUEST BOOK</span></h5>
                {/* <button type="button" className="close" onClick={handleClose}>
                  <span>&times;</span>
                </button> */}
              </div>
              <form onSubmit={sendData}>
                <div className="modal-body">
                    <div className="mb-3">
                    <label htmlFor="" className="form-label">Name</label>
                    <input type="text"
                        className="form-control" name="" id="" value={data.nama} onChange={(e)=> setData({...data, nama:e.target.value}) } aria-describedby="helpId" placeholder="" required />
                        {/* <small id="helpId" className="form-text text-muted">Help text</small> */}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Email</label>
                        <input type="text" className="form-control" value={data.email} onChange={(e)=> setData({...data, email:e.target.value}) }  name="" id="" aria-describedby="helpId" placeholder="" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">No Telepon</label>
                        <input type="text" className="form-control" value={data.telepon} onChange={(e)=>setData({...data, telepon:e.target.value})} name="" id="" aria-describedby="helpId" placeholder="" required />
                    </div>
                </div>
                <div className="modal-footer">
                    {/* <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    Tutup
                    </button> */}
                    <button type="submit"  className="button">
                        SEND
                    </button>
                </div>
              </form>
              
            </div>
          </div>
        </div>
      )}

    {showModal ? <div className="modal-backdrop fade show"></div> : null}
    </>
  )
}

export default Modaldata