import React from 'react'
import Modaldata from '../../components/Modaldata'
import { Link } from 'react-router-dom';

const Public = () => {
  // let check = localStorage.getItem('regFree');
  return (
    <>  
      {/* {localStorage.getItem('regFree') !== "true" || localStorage.getItem('regFree') === null ? (
              <Modaldata />
            ) : (
              <div></div>
            )} */}
            
          <Modaldata />
        <div className="row justify-content-center align-items-center g-2 py-5 px-4">
            <div className="col-md-6 d-flex justify-content-center mb-10">
              <Link to={`/Ourclass/Class/buy`}>
                <div className="card card-content">
                  <img className="card-img-top" src={require('../../assets/images/Buy.png')} alt="Title" />
                  <div className="card-body">
                      <button className="btn btn-primary btn-block">Select Buy Class</button>
                  </div>
                </div>
              </Link>
                
            </div>
            <div className="col-md-6 d-flex justify-content-center mb-10">
              <Link to={`/Ourclass/Class/free`}>
                    <div className="card card-content">
                      <img className="card-img-top" src={require('../../assets/images/Free.png')} alt="Title" />
                    <div className="card-body">
                      <button className="btn btn-primary btn-block">Select Free Class</button>
                    </div>
                  </div>
              </Link>
            </div>
            </div>


            
    </>
  )
}

export default Public