import React, { useState } from 'react'
import { createGuest } from '../store/Guest';
import { useDispatch } from 'react-redux';
// const title = "Get Your Free Membership & Let's Join Our Forum ";
const title = "Unduh Profil Perusahaan dan Katalog Program Kami";
const btnText = "Unduh";

const Registry = () => {
    const dispatch = useDispatch();
    const [data,setData] = useState({nama:'',email:'',telepon:''});

    // const handleShow = () => setShowModal(true);
    const sendData = async (e)=>{
        e.preventDefault();
        await dispatch(createGuest(data));
        await localStorage.setItem('regFree',true);
        await localStorage.setItem('email_user',data.email);
        window.alert('Permintaan anda telah berhasil, silahkan mengunduh atau kami akan menghubungi anda');
        await handleDownload();
        setData({...data,nama:'',email:'',telepon:''})
    }

    const handleDownload = async() => {
        try {
            const response = await fetch('assets/profil_perusahaan_dan_katalog.pdf'); // Ganti dengan path yang sesuai
            if (!response.ok) {
                throw new Error('File tidak ditemukan');
            }

            const link = document.createElement('a');
            link.href = require('../assets/profil_perusahaan_dan_katalog.pdf'); // Replace with the actual path to your file
            link.download = 'profil_perusahaan_dan_katalog.pdf'; // Replace with the desired file name
            link.click();
            
        } catch (error) {
            console.log(error);
        }
    };


  return (
    <div className="workshop-join padding-tb">
        <div className="container">
            <div className="section-wrapper">
                <div className="workshop-title">
                    <h5>{title}</h5>
                </div>
                <div className="workshop-join-form">
                    <form onSubmit={sendData}>
                        <div className="input-area">
                            <input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={data.nama}
                                onChange={(e)=> setData({...data, nama:e.target.value}) }
                                required
                            />
                            <input
                                type="email"
                                name="name"
                                placeholder="Email"
                                value={data.email}
                                onChange={(e)=> setData({...data, email:e.target.value}) }
                                required
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Phone"
                                value={data.telepon}
                                onChange={(e)=> setData({...data, telepon:e.target.value}) }
                                required
                            />
                        </div>
                        <div className="submit-area">
                            <button type="submit" className="lab-btn"><span>{btnText}</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Registry