// import { useEffect } from "react";
import {BrowserRouter,Routes,Route,Outlet,Navigate} from "react-router-dom"
import 'swiper/css';
import './mystyle.scss';
import Base from "./layout/Base";
import Home from "./Pages/Home";

import News from "./Pages/news/news";
import NewsDetail from "./Pages/news/NewsDetail"; 
// import Class from './Pages/about/Class';
import Services from "./Pages/service/Services";
import Facilitator from './Pages/about/Facilitator';
import FacilitatorSingle from "./Pages/about/FacilitatorSingle";
import Coaching from "./Pages/service/Coaching";
import Consulting from "./Pages/service/Consulting";
import Eventorg from "./Pages/service/Eventorg";
import Mentoring from "./Pages/service/Mentoring";
import Outbond from "./Pages/service/Outbond";
import Training from "./Pages/service/Training";

import Story from "./Pages/about/Story";

// ====== OurClass =================================================
import MostFav from "./Pages/class/MostFav";
import Public from "./Pages/class/Public";
import Inhouse from "./Pages/class/Inhouse";
import ClassDetail from "./Pages/class/ClassDetail";
import Class from "./Pages/class/Class";

// ====================== Forum ===================================
import Case from "./Pages/forum/Case";
import Coachingforum from "./Pages/forum/Coachingforum";
import Counseling from "./Pages/forum/Counseling";
import Diskusi from "./Pages/forum/Diskusi";

//========================= cart ==================================
import CartPage from "./Pages/cart/CartPage";

//============== register or signin ================================
import Register from './Pages/Register'
import Login from './Pages/Login';

//======================== LMS ===================================
import RunningProgram from "./Pages/lms/RunningProgram";

import ErrorPage from "./Pages/404";


import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loginState } from "./store/Member";


function App() {
  
  const dispatch = useDispatch();

  const statusLogin = useSelector(state=>state.Member.isLoggedIn);


  useEffect(()=>{
      if(localStorage.getItem('isLogin') === 'true'){
        dispatch(loginState(true));
      }
     // console.log(statusLogin)
  },[statusLogin,dispatch])


  return (
    <BrowserRouter>
      <Base>
        <Routes>
           <Route path="/" element={<Home />} />
           <Route path="/news" element={<News />} />
           <Route path="/news/:id_berita" element={<NewsDetail />} />
           <Route path="About" element={<Outlet />} >
              {/* <Route index element={<About />} /> */}
              {/* <Route path="Class" element={<Class />} /> */}
              <Route path="Story" element={<Story />} />
              <Route path="Facilitator" element={<Facilitator />} />
              <Route path="Facilitator_single/:id_fasilitator" element={<FacilitatorSingle />} />
            </Route>
            <Route path="Services" element={<Outlet />} >
              <Route index element={<Services />} />
              <Route path="Coaching" element={<Coaching />} />
              <Route path="Consulting" element={<Consulting />} />
              <Route path="Eventorg" element={<Eventorg />} />
              <Route path="Mentoring" element={<Mentoring />} />
              <Route path="Outbond" element={<Outbond />} />
              <Route path="Training" element={<Training />} />
            </Route>
            <Route path="Ourclass" element={<Outlet />}>
              <Route path="MostFav" element={<MostFav />} />
              <Route path="Public" element={<Public />} />
              <Route path="Inhouse" element={<Inhouse />} />
              <Route path="Class" element={<Class />} />
              {/* <Route path="ClassDetail/:id_produk" element={ statusLogin ? <ClassDetail /> : <Navigate to="/Login" /> } /> */}
              <Route path="ClassDetail/:id_produk" element={ <ClassDetail /> } />
            </Route>
            <Route path="Forum" element={<Outlet />}>
              <Route path="Diskusi" element={<Diskusi />} />
              <Route path="Counseling" element={<Counseling />} />
              <Route path="Coachingforum" element={<Coachingforum />} />
              <Route path="Case" element={<Case />} />
            </Route>
            <Route path="Cart/:id_member" element={<Outlet />} >
                <Route index element={ statusLogin ? <CartPage /> : <Navigate to="/Login" /> }  />
            </Route>
            <Route path="RunningProgram" element={<RunningProgram />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Login" element={<Login />} />
           <Route path="*" element={< ErrorPage />} />
        </Routes>
      </Base>
    </BrowserRouter>
  );
}

export default App;
