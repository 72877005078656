import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// import qs from "qs";


export const getProduk = () => async(dispatch) =>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}produk/get_produk`);
        return dispatch(setProduk(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const getProdukDisplay = () => async(dispatch) =>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}produk/get_produk_display`);
        return dispatch(setProdukDisplay(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const getProdukFav = () => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}produk/get_produk_fav`);
        return dispatch(setProdukFav(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const getProdukMore = () => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}produk/get_produk_more`);
        return dispatch(setProdukMore(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const getProdukId = (id_produk) =>async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}produk/get_produk_id/${id_produk}`);
        return dispatch(setProdukId(res.data))
    } catch (error) {
        console.log(error);
    }
}

export const getLayanan = () => async(dispatch)=>{
    try {
        let res = await axios.get(`${process.env.REACT_APP_URL}produk/get_layanan`);
        return dispatch(setLayanan(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const getAbout = () => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}admin/get_about`);
        return await dispatch(setAbout(res.data));
    } catch (error) {
        console.log(error)
    }
}

export const getNews = () => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}admin/get_berita`);
        // console.log(res.data)
        return await dispatch(setNews(res.data));
    } catch (error) {
        console.log(error)
    }
}
export const getNewsId = (id_berita) => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}admin/get_berita`);
        const fil = res.data.find(row => parseInt(row.id_berita) === parseInt(id_berita));
        return await dispatch(setNewsId(fil));
    } catch (error) {
        console.log(error)
    }
}

const Produk = createSlice({
    name:'produk',
    initialState:{
            produk:[],
            produkFav:[],
            produkMore:[],
            produkId:[],
            layanan:[],
            produkDisplay:[],
            about:[],
            news:[],
            newsId:[]
        },
    reducers:{
        setProduk(state,action){
            state.produk = action.payload;
        },
        setProdukDisplay(state,action){
            state.produkDisplay = action.payload;
        },
        setProdukFav(state,action){
            state.produkFav = action.payload
        },
        setProdukMore(state,action){
            state.produkMore = action.payload
        },
        setProdukId(state,action){
            state.produkId = action.payload
        },
        setLayanan(state,action){
            state.layanan = action.payload;
        },
        setAbout(state,action){
            state.about = action.payload;
        },
        setNews(state,action){
            state.news = action.payload;
        },
        setNewsId(state,action){
            state.newsId = action.payload;
        }
    }
});

export const {setProduk,setProdukDisplay,setProdukFav,setProdukMore,setProdukId,setLayanan,setAbout,setNews,setNewsId} = Produk.actions;

export default Produk;