import React from 'react';
import PageHeader from '../../components/Pageheader';

const Coachingforum = () => {
  return (
    <>
        <PageHeader title="COACHING" />
    </>
  )
}

export default Coachingforum