import React,{useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";


const categoryList = [
    {
        imgUrl: 'assets/images/category/icon/07.jpg',
        imgAlt: 'Training',
        title: 'TRAINING',
        desc: '',
        link:'Training'
    },
    {
        imgUrl: 'assets/images/category/icon/08.jpg',
        imgAlt: 'coaching',
        title: 'COACHING',
        desc: '',
        link:'Coaching'
    },
    {
        imgUrl: 'assets/images/category/icon/09.jpg',
        imgAlt: 'counsulting',
        title: 'CONSULTING',
        desc: '',
        link:'Consulting'
    },
    {
        imgUrl: 'assets/images/category/icon/10.jpg',
        imgAlt: 'Mentoring',
        title: 'MENTORING',
        desc: '',
        link:'Mentoring'
    },
    {
        imgUrl: 'assets/images/category/icon/11.jpg',
        imgAlt: 'Out Bound',
        title: 'OUT BOUND',
        desc: '',
        link:'Outbond'
    },
    {
        imgUrl: 'assets/images/category/icon/12.jpg',
        imgAlt: 'Event Organizier',
        title: 'EVENT ORGANIZIER',
        desc: '',
        link:'Eventorg'
    },
]

const Services = () => {
    const navigate = useNavigate();

    const [animationIndex, setAnimationIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if(animationIndex >= 6 )
            setAnimationIndex(0);
        setAnimationIndex((prevIndex) => prevIndex + 1 ); // Ganti angka 2 dengan jumlah kelas animasi yang ingin digunakan
      }, 3000);
  
      return () => {
        clearInterval(interval);
      };
    }, [animationIndex]);

    const handleNav = (nav) => {
            navigate(nav);
    }


    

  return (
    <>    
    <div className="category-section padding-tb section-bg style-2">
        <div className="container">
            <div className="section-header text-center">
                <h2 className="title"><span style={{color:'#ff8000'}}>GO</span><span style={{color:'#0080ff'}}> ORBIT</span></h2>
                <span className="subtitle" style={{color:'#808080'}}>Services</span>
            </div>
            <div className="section-wrapper ">
                <div className="row g-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                    {categoryList.map((val, i) => (
                        <div className={`col ${animationIndex === i+1 ? 'animation' : ''}`} key={i}>
                            <div className="category-item text-center">
                            <div className="category-inner" onClick={ ()=> handleNav(val.link)}>
                                    <div className="category-thumb">
                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                    </div>
                                    <div className="category-content">
                                        <h6>{val.title}</h6>
                                        <span>{val.desc}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default Services