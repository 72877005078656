import React from 'react'

const featureLeftList = [
    {
        iconName: 'icofont-calculations',
        desc: 'Customize Program dari hasil konsultasi program secara gratis',
    },
    {
        iconName: 'icofont-light-bulb',
        desc: 'Kesiapan pelaksanaan dan Networking ada di seluruh Indonesia',
    },
    {
        iconName: 'icofont-graduate',
        desc: 'Subject Mater Expert/ faculty member yang profesional',
    },
]

const featureRightList = [
    {
        iconName: 'icofont-certificate',
        desc: 'Faculty Member yang bersetifikasi dan wajib berkompeten.',
    },
    {
        iconName: 'icofont-learn',
        desc: 'Proses transformasi belajar yang sederhana dan Implementatif',
    },
    {
        iconName: 'icofont-puzzle',
        desc: 'Kemasan dan Program dibuat dengan kreatif serta menyenangkan.',
    },
]

const Collaborate = () => {
  return (
    <section className="feature-section style-2 padding-tb pb-5 collaborate ">
        <div className="container">
            <div className="section-header text-center">
                <h2 className="title"><span style={{color:'#ff8000'}}>WHY COLLABORATE</span> <span style={{color:'#0080ff'}}>WITH US ?</span></h2>
            </div>
            <div className="section-wrapper">
                <div className="row g-4 justify-content-center align-items-center">
                    <div className="col-lg-4 col-sm-6 col-12 order-lg-0">
                        <div className="left text-lg-end">
                            {featureLeftList.map((val, i) => (
                                <div className="feature-item" key={i}>
                                    <div className="feature-inner flex-lg-row-reverse">
                                        <div className="feature-icon">
                                            <i className={val.iconName}></i>
                                        </div>
                                        <div className="feature-content">
                                            <p style={{color:'#0390fc',fontWeight:'bold'}}>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 order-lg-2">
                        <div className="right">
                            {featureRightList.map((val, i) => (
                                <div className="feature-item" key={i}>
                                    <div className="feature-inner">
                                        <div className="feature-icon">
                                            <i className={val.iconName}></i>
                                        </div>
                                        <div className="feature-content">
                                            <p style={{color:'#0390fc',fontWeight:'bold'}} >{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 order-lg-1">
                        <div className="feature-thumb">
                            <div className="abs-thumb">
                                <img src={require("../assets/images/banner.png")} alt="education" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Collaborate