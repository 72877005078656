import React from 'react'

import CountUp from 'react-countup';

// const subTitle = "START TO SUCCESS";
// const title = "Start Your success With Orbitian";


const achievementList = [
    {
        count: '300',
        desc: 'Corporate and Public Client',
    },
    {
        count: '10000',
        desc: 'Lesson Hour',
    },
    {
        count: '60',
        desc: 'Certified Expertise Coach',
    },
    {
        count: '130',
        desc: 'Training and Consultancy Program',
    },
]


const Achivement = () => {
  return (
    <div className="achievement-section padding-tb">
        <div className="container">
            <div className="section-header text-center">
                {/* <span className="subtitle">{subTitle}</span> */}
                <h2 className="title"><span style={{color:'#ff8000'}}>START YOUR SUCCESS</span> <span style={{color:'#0080ff'}}>WITH ORBITIAN</span></h2>
            </div>
            <div className="section-wrapper">
                <div className="counter-part mb-4">
                    <div className="row g-4 row-cols-lg-4 row-cols-sm-2 row-cols-1 justify-content-center">
                        {achievementList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="count-item">
                                    <div className="count-inner">
                                        <div className="count-content">
                                            <h2 style={{color:'#0390fc'}}><span className="count"><CountUp end={val.count} /></span><span>+</span></h2>
                                            <p style={{color:'#ff5a0b'}} >{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Achivement