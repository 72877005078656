import React from 'react';
import { Link } from 'react-router-dom';
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import Registry from './Registry';
// import Rating from './Rating';

const subTitle = "GO ORBIT";
const title = <h2 className="title" style={{color: '#3393FF'}}><span className="d-lg-block">Empower You</span> On A Perfect <span className="d-lg-block">Orbital Path!</span></h2>;
const desc = "";


const sponsorList = [
    {
        imgUrl: require('../assets/klien/klien2.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien3.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien4.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien5.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien6.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien7.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien8.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien9.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien10.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien11.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien12.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien13.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien14.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien15.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien16.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien17.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien18.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien19.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien20.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien21.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien22.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien24.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien25.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien26.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien27.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien28.png'),
        imgAlt: 'Klien',
    },
    {
        imgUrl: require('../assets/klien/klien29.png'),
        imgAlt: 'Klien',
    },
]

// const backgroundImg = require('');

const testimoni = [
    {
        imgUrl: require('../assets/testi/testi1.jpg'),
        imgAlt: 'testimoni',
        name: 'Ranov Fasalo',
        degi: 'CEO PT MMP Kutai Mahakam',
        desc: '"Memberikan materi yang aplikatif dan lengkap dengan praktek yang mudah diterapkan. Detail program, fasilitas belajar sangat diperhatikan. sampai urusan warna saja sangat impresif"',
    },
    {
        imgUrl: require('../assets/testi/yuyun.png'),
        imgAlt: 'testimoni',
        name: 'Mahfuzoh (Yuyun)',
        degi: 'HRD Manager PT SPR Langgak',
        desc: '”Konsultasi dan Analisa kebutuhan program sangat profesional, solutif sampai tuntas dan gratis. Program konsultansi manajemen organisasi dan SDM kami dengan Go Orbit sangat komprehensif dan berdampak”',
    },
    {
        imgUrl: require('../assets/testi/testi5.png'),
        imgAlt: 'testimoni',
        name: 'Slamet Purwanto',
        degi: 'Vice President Human Capital Bank Jatim',
        desc: '"Officer Development Program yang di design sesuai dengan kebutuhan dan issue ter update. Program 5 Hari pun terasa sangat efisien"',
    },
    {
        imgUrl: require('../assets/testi/testi6.png'),
        imgAlt: 'testimoni',
        name: 'Didid Handoko',
        degi: 'Branch Manager PT Pelindo Terminal',
        desc: '"Sangat memahami bisnis dari BUMN, program team building dan outbound trainingnya terintegrasi dengan budaya perusahaan, juga aplikatif"',
    },
    {
        imgUrl: require('../assets/testi/ahdiat.png'),
        imgAlt: 'testimoni',
        name: 'Muhammad Ahdiat, ST',
        degi: 'Direktur Utama PAM Bandarmasih',
        desc: '"Program Persiapan Pensiun berbeda sangat aplikatif dan tepat tujuan, event dikemas sangat fun dan memberikan insight praktis pensiunan"',
    },
    {
        imgUrl: require('../assets/testi/testi4.jpg'),
        imgAlt: 'testimoni',
        name: 'Umi Nimatus Zahro',
        degi: 'HR Manager PT Bimasakti Grup',
        desc: '"Seluruh peserta training kami merasa puas dengan design belajar dan deliverable yang mengesankan, tim fasilitator di atas rata rata, yang terpenting worksheet dan evaluasi programnya sangat membantu"',
    },
]

const Banner3 = () => {
    
  return (
    <>  
        <section className="banner-section style-2">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-xxl-5 col-xl-6 col-lg-10 me-auto">
                            <div className="banner-content"> 
                                <h6 className="subtitle text-uppercase fw-medium">{subTitle}</h6>
                                {title}
                                <p className="desc">{desc}</p>
                                <Swiper
                                        spaceBetween={20}
                                        slidesPerView={2}
                                        loop={'true'}
                                        autoplay={{
                                            delay: 7000,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay]}
                                        breakpoints={{
                                            0: {
                                                width: 0,
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                width: 768,
                                                slidesPerView: 1,
                                            },
                                            1200: {
                                                width: 1200,
                                                slidesPerView: 1,
                                            },
                                        }}
                                        className="swiper-mobile-hidden"
                                    >
                                        <div className="col">
                                                {testimoni.map((val, i) => (
                                                <SwiperSlide key={i}>
                                                    <div className="stu-feed-item" key={i}>
                                                        <div className="stu-feed-inner">
                                                            <div className="stu-feed-top">
                                                                <div className="sft-left">
                                                                    <div className="sftl-thumb">
                                                                        <img src={`${val.imgUrl}`} alt={val.imgAlt} style={{width:'60px',height:'60px',objectFit: 'cover'}} />
                                                                    </div>
                                                                    <div className="sftl-content">
                                                                        <Link to="/team-single"><h6>{val.name}</h6></Link>
                                                                        <span>{val.degi}</span>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="sft-right">
                                                                    <Rating />
                                                                </div> */}
                                                            </div>
                                                            <div className="stu-feed-bottom">
                                                                <p style={{fontStyle:'italic'}}>{val.desc}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </div>
                                        
                                    </Swiper>
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6">
                            <div className="thumb-part">
                                <div className="banner-thumb text-center">
                                    <img src="assets/images/banner/pp_new.png" alt="img" />
                                </div>
                                <div className="abs-thumb d-none d-xxl-block">
                                    <img src="assets/images/banner/03.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section>
            <Registry />
        </section>
        <section>
            <div className="sponsor-section display-klien">
                    <div className="container">
                        <div className="section-header text-center">
                            {/* <span className="subtitle">{subTitle}</span> */}
                            <h2 className="title titleClients"><span style={{color:'#ff8000'}}>OUR</span> <span style={{color:'#0080ff'}}> CLIENTS</span></h2>
                        </div>
                            <div className="section-wrapper">
                                <div className="sponsor-slider">
                                    <Swiper
                                        spaceBetween={20}
                                        slidesPerView={2}
                                        loop={'true'}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay]}
                                        breakpoints={{
                                            0: {
                                                width: 0,
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                width: 768,
                                                slidesPerView: 3,
                                            },
                                            1200: {
                                                width: 1200,
                                                slidesPerView: 5.5,
                                            },
                                        }}
                                    >
                                        {sponsorList.map((val, i) => (
                                            <SwiperSlide key={i}>
                                                <div className="sponsor-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div
                                                        style={{
                                                            width: '240px',
                                                            height: '110px',
                                                            margin: '10px',
                                                            overflow: 'hidden', // To avoid overflow if the image is too large
                                                        }}
                                                        >
                                                        <img
                                                            src={val.imgUrl}
                                                            alt="Gambar"
                                                            style={{
                                                            width: '100%', // The image will take 100% width of the div
                                                            height: '100%', // The image will take 100% height of the div
                                                            objectFit: 'contain', // The entire image will be visible within the div without distortion
                                                            }}
                                                        />
                                                        </div>



                                                    {/* <div className="sponsor-thumb" >
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} style={{margin: '0 15px', objectFit:'cover',width:'100%',height:'100%' }} />
                                                    </div> */}
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                </div>
        </section>
    </>

  )
}

export default Banner3