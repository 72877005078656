import React from 'react'
import PageHeader from '../../components/Pageheader'

const Diskusi = () => {
  return (
    <>
        <PageHeader title="DISCUSSION FORUM" />
    </>
  )
}

export default Diskusi