import { Fragment, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginState } from "../store/Member";
import { useEffect } from "react";



const Header = () => {

    const location = useLocation();

    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);
	const [searchTrigger, setSearchTrigger] = useState(false);

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});

    const dispatch = useDispatch();

    // const isLogin = localStorage.getItem('isLogin');

    const logOut = async() =>{
        localStorage.removeItem('isLogin');
        localStorage.removeItem('email');
       await dispatch(loginState(false));
    }

    const loggedIn = useSelector(state=>state.Member.isLoggedIn);

    useEffect(()=>{
        if(menuToggle){
            setMenuToggle(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location]);


    const handleScroll = () => {
        if (window.scrollY > 200 && menuToggle) {
            setMenuToggle(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuToggle]);
    
    return (
        <Fragment>
            <header className={`header-section style-3 ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
                <div className={`header-top ${socialToggle ? "open" : ""}`}>
                    <div className="container">
                        <div className="header-top-area">
                            <div className="header-top-contact">
                                <a href="/#" className="lab-btn"><span>For Your Question</span></a>
                                <a href="tel:+628115184818">0811 518 4818</a>
                                <a href="mailto:go.orbittraining@gmail.com">go.orbittraining@gmail.com</a>
                            </div>
                            <div className="header-top-reglog">
                                { !loggedIn ? 
                                        <>
                                            <Link to="/login" className="login">Login {loggedIn}</Link>
                                            <Link to="/register" className="signup">Sign Up</Link>
                                        </>  
                                    : <Link className="login" to="/" onClick={()=>logOut()}> Logout</Link>
                                }
                                

                                {/* <Link to="/Cart/1" className="cart"><i className="icofont-cart" style={{fontSize: "1.5em"}} ></i>cart</Link>  */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo-search-acte">
                                <div className="logo">
                                    <Link to="/"><img src={require("../assets/images/orbit.png" )} style={{ width: '200px', height: '50px' }} className="mt-1 mb-1" alt="logo" /></Link>
                                </div>
                            </div>
                            <div className="menu-area">
                                <div className="menu">
                                    <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                        {/* <li><NavLink to="/">Home</NavLink></li> */}
                                        &nbsp;
                                        <li className="menu-item-has-children">
                                            <a href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">About Us</a>
                                            <ul className="lab-ul dropdown-menu">
                                                <li><NavLink to="About/Story">Our Story</NavLink></li>
                                                <li><NavLink to="About/Facilitator">Facilitator Profile</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink to="/Services">Our Services</NavLink></li>
                                        <li className="menu-item-has-children">
                                            <a href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Our Program</a>
                                            <ul className="lab-ul dropdown-menu">
                                                <li><NavLink to="Ourclass/MostFav">Most Favorite Program</NavLink></li>
                                                <li><NavLink to="Ourclass/Class">Public Class</NavLink></li>
                                                <li><NavLink to="Ourclass/Inhouse">In House Class</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">
                                                ATLAS
                                            </a>
                                            <ul className="lab-ul dropdown-menu">
                                                <li><NavLink to="/RunningProgram">Learning Assistant</NavLink></li>
                                            </ul>
                                        </li>
                                       
                                    </ul>
                                </div>

                                <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                                <div className="ellepsis-bar d-lg-none" onClick={() => setSocialToggle(!socialToggle)}>
                                    <i className="icofont-info-square"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className={`menu-search-form ${searchTrigger ? "open" : ""}`}>
                <div className="search-close" onClick={() => setSearchTrigger(!searchTrigger)}></div>
                <form>
                    <input type="text" name="search" placeholder="Search here..." />
                    <button type="submit"><i className="icofont-search"></i></button>
                </form>
            </div>
        </Fragment>
    );
}
 
export default Header;

