
import { Link } from "react-router-dom";

const kontak = "Kontak Kami";
const quickTitle = "Quick Links";

const addressList = [
    {
        iconName: 'icofont-google-map',
        text: 'Surabaya - Head Office',
        detail:' YKP Pandugo I Blok PB No. 36 Penjaringan Sari - Rungkut Surabaya'
    },
    {
        iconName: 'icofont-google-map',
        text: 'Jakarta - Representative Office',
        detail:'The CEO Building - vOffice Level 12, Jl. TB Simatupang No. 18 C, Cilandak Jakarta Selatan - DKI Jakarta'
    },
    {
        iconName: 'icofont-phone',
        text: '+62 811 518 4818',
    },
    {
        iconName: 'icofont-envelope',
        text: 'go.orbittraining@gmail.com',
    },
]

const socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/orbit.training/',
        className: 'facebook',
    },
    {
        iconName: 'icofont-youtube',
        siteLink: 'https://www.youtube.com/@goorbit2808/',
        className: 'youtube',
    },
    {
        iconName: 'icofont-instagram',
        siteLink: 'https://www.instagram.com/go.orbit/',
        className: 'instagram',
    },
    {
        iconName: 'icofont-linkedin',
        siteLink: 'https://www.linkedin.com/company/go-orbit-training-and-consulting/',
        className: 'linkedin',
    },
    // {
    //     iconName: 'icofont-tiktok',
    //     siteLink: 'https://www.tiktok.com/@go.orbit?_t=8o7DhH1idAy&_r=1',
    //     className: 'tiktok',
    // },
]


const quickList = [
    {
        text: 'Favorit Program',
        link: '/Ourclass/MostFav',
    },
    {
        text: 'Training',
        link: '/Services/training',
    },
    {
        text: 'Coaching',
        link: '/Services/consulting',
    },
    {
        text: 'Consulting',
        link: '/Services/consulting',
    },
    {
        text: 'Mentoring',
        link: '/services/mentoring',
    },
    {
        text: 'Outbound',
        link: '/Services/Outbound',
    },
    {
        text: 'Event Organizier',
        link: '/Services/EventOrg',
    },
]



const Footer = () => {
    return (
        <footer className="style-2">
            <div className="footer-top padding-tb">
                <div className="container">
                    <div className="row g-4 row-cols-xl-4 row-cols-sm-2 row-cols-1 justify-content-center">
                        <div className="col">
                            <div className="footer-item our-address">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <img src={require("../assets/images/orbit_footer.png")} height="200" width="200"alt="education" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{kontak}</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul office-address">
                                                {addressList.map((val, i) => (
                                                    <li key={i}><i className={val.iconName}></i>
                                                    {val.text}<br/>
                                                    <span>{val.detail}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{quickTitle}</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul">
                                                {quickList.map((val, i) => (
                                                    <li key={i}><Link to={val.link}>{val.text}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-item our-address">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>Follow Us</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul social-icons">
                                                {socialList.map((val, i) => (
                                                    <li key={i}>
                                                        <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="footer-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <p>&copy; 2021 <Link to="/">Edukon</Link> Designed by <a href="https://themeforest.net/user/CodexCoder" rel="noopener noreferrer" target="_blank">CodexCoder</a> </p>
                        <div className="footer-bottom-list">
                            {footerbottomList.map((val, i) => (
                                <a href={val.link} key={i}>{val.text}</a>
                            ))}
                        </div>
                    </div>
                </div>
            </div> */}
        </footer>
    );
}
 
export default Footer;