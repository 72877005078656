import {useState,useEffect } from 'react';
import { createSignIn } from '../../store/Member';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Confirmed from '../../assets/confirmed.png';

const btnText = "CONFIRM NOW";

const title2 = "Confirm Has Been Success!";
const desc = "";
const btnText2 = "Go Back To Home";


const RunningProgram = () => {
    
    const dispatch = useDispatch();
    
    const [input,setInput] = useState({nama:'',email:'',judul_program:'',telepon:''});
    const [loading,setLoading] = useState(false);

    const [confirm,setConfirm] = useState(false);

    const submitReg = async(e)=>{
        e.preventDefault();
        setLoading(true);
        await dispatch(createSignIn(input)).then(async()=>{
            await setLoading(false);
            await localStorage.setItem('confirm',true);
            setConfirm(true);
            // await localStorage.setItem('email',input.email);
            // await dispatch(loginState(true));
        }).catch((e)=>{
            console.log(e);
        })
    }

    useEffect(()=>{
        if(localStorage.getItem('confirm')){
            setConfirm(true);
        }
    },[]);

    const confirmUlang = async() =>{
        await localStorage.removeItem('confirm')
        setConfirm(false);
    }

  return (
    <>
        {confirm !== true ?  (
                    <div className="login-section padding-tb section-bg">
                    <div className="container">
                        <div className="account-wrapper">
                            <div className="logo">
                                 <Link to="/"><img src={require("../../assets/images/orbit.png" )} style={{ width: '200px', height: '50px' }} className="mt-1 mb-1" alt="logo" /></Link>
                            </div>
                            <div style={{border:"1px solid grey",margin:"10px 0"}}>
                                
                            </div>
                            <div className="title">
                                <h3 className="title" >
                                    <span style={{color:'#ff8000'}} >CONFIRM</span><span style={{color:'#0080ff'}}> PROGRAM </span>
                                </h3>
                            </div>
                            <form className="account-form" onSubmit={submitReg}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="nama"
                                        placeholder="Name"
                                        onChange={(e)=>setInput({...input,nama:e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        onChange={(e)=>setInput({...input,email:e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="judul_program"
                                        placeholder="Judul Program"
                                        onChange={(e)=>setInput({...input,judul_program:e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="telepon"
                                        placeholder="Telepon"
                                        onChange={(e)=>setInput({...input,telepon:e.target.value})}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <button className="loading-container" disabled={loading?true:false} >{ loading ? <div className="loading-button"></div> : <span>{btnText}</span>} </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        )  : (
            <div className="four-zero-section padding-tb section-bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-sm-6 col-12">
                            <div className="four-zero-content">
                                {/* <Link to="/">
                                    <img src="assets/images/logo/01.png" alt="CodexCoder" />
                                </Link> */}
                                <h3 className="title" style={{color: '#5757ff'}}>{title2}</h3>
                                <p>{desc}</p>
                                <Link to="/" className="lab-btn"><span>{btnText2} <i className="icofont-external-link"></i></span></Link>
                                <p style={{paddingTop:"10px"}}> -Or- </p>
                                <button style={{backgroundColor: 'rgb(241,97,38)',width: '250px',borderRadius:'5px',fontWeight:'bold'}} onClick={confirmUlang}> <span>Konfirm Ulang Program <i className="icofont-external-link"></i></span></button>
                            </div>
                        </div>
                        <div className="col-lg-8 col-sm-6 col-12">
                            <div className="foue-zero-thumb">
                                <img src={Confirmed} alt="Confirmed" />
                            </div>
                        </div>
                    </div>
            </div>
        </div>  
        ) }



    </>
  )
}

export default RunningProgram