import React,{useEffect,useState} from 'react'

import { Link } from "react-router-dom";
import { getProdukFav,getProdukDisplay,getProdukMore } from '../../store/Produk';

import {useDispatch,useSelector} from 'react-redux';
import { AES } from 'crypto-js';
import FloatingConsult from '../../components/FloatingConsult';

import HTMLReactParser from 'html-react-parser';
import Modaldata from '../../components/Modaldata';

const subTitle = "Featured Courses";
// const title = "Most Favorite Program";

const MostFav = () => {
    const dispatch = useDispatch();

    const [moreProduk,setMoreProduk] = useState(false);

    const [showModal,setShowModal] =useState(false);

    const dataProduk = useSelector(state => state.Produk.produkFav);

    let dataDisplay = useSelector(state=>state.Produk.produkDisplay);
    
    const [produkDisplay,setProdukDisplay] = useState([]);    
    
    const loadMore = async() =>{
        setMoreProduk(true);
        await dispatch(getProdukMore()).then(()=>{
            setMoreProduk(false);
            // console.log(typeof(dataMore.length))
        }).catch((error)=>{
           console.log(error)
        })
    }

    const dataMore = useSelector(state=>state.Produk.produkMore);

    useEffect(()=>{
        dispatch(getProdukFav());
        dispatch(getProdukDisplay());
    },[dispatch]);

    useEffect(() => {
        setProdukDisplay(dataDisplay)
    }, [dataDisplay]);

    let dataFil =  dataProduk.length !== 0 ? dataProduk : produkDisplay;

    const clickItemHandler = () =>{
       setShowModal(true);
    }

  return (
    <>
    { showModal !== false ? <Modaldata /> : '' }
    <FloatingConsult />
    <div className="course-section padding-tb section-bg">
        <div className="container">
        <div className="section-header text-center">
            <span className="subtitle" >{subTitle}</span>
            <h2 className="title" ><span style={{color:'#ff8000'}} >FAVORITE PROGRAM</span><span style={{color:'#0080ff'}}> OF THE MONTH</span></h2>
        </div>
            <div className="section-wrapper">
                <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
                {dataFil.map((row, index) => (
                        <div className="col" key={index}>
                            <div className="course-item" onClick={clickItemHandler}>
                                <div className="course-inner">
                                    <div className="course-thumb" >
                                        <img src={`${ process.env.REACT_APP_URL+'assets/foto/'+row.foto}`} alt={`${row.foto}`} style={{objectFit:'contain',height:'auto',minHeight:'280px'}} />
                                    </div>
                                    <div className="course-content">
                                        {row.display !== '1' ? (
                                            <div className="course-price" style={{minWidth:'25vh',borderRadius:'10px'}}><i className="icofont-calendar"></i>
                                                {new Date(JSON.parse(row.tgl_program)[0]).toLocaleDateString('id-ID',{day:'numeric'}) +" - "+new Date(JSON.parse(row.tgl_program)[1]).toLocaleDateString('id-ID',{day:'numeric',month:"long",year:"numeric"}) }
                                            </div>
                                        ) : ''}
                                        {/* <div className="course-category">
                                            <div style={{backgroundColor:'grey',padding:'5px',marginBottom:'12px',borderRadius:'4px',color:'#ffffff'}}>
                                                {row.kategori}
                                            </div>
                                        </div> */}
                                        <Link to={row.display !== '1' ? `/Ourclass/ClassDetail/${encodeURIComponent(AES.encrypt(row?.id_produk.toString(), 'goorbit').toString())}` : ''} ><h4>{row.title}</h4></Link>
                                        <div className="course-details">
                                             { row.display !== '1' ? <div className="couse-count"><i className="icofont-people"></i>{row.jumlah_peserta+` Peserta`}</div> : <div className="couse-count">{HTMLReactParser(row.deskripsi_program)}</div> }
                                        </div>
                                        { row.display !== '1' ? <div className="course-footer">
                                            <div className="course-btn">
                                                <Link to={`/Ourclass/ClassDetail/${encodeURIComponent(AES.encrypt(row?.id_produk.toString(), 'goorbit').toString())}`}  className="lab-btn-text">{`ORDER NOW`} <i className="icofont-external-link"></i></Link>
                                            </div>
                                        </div> : null  }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <hr className="divider" />

               { dataMore.length > 0 || (!moreProduk ? <button type="button" onClick={loadMore} >Load More </button> : '')}
                
                <div className="loading-container">
                    {moreProduk ? (
                        <div className="loading-spinner"></div>
                    ) : (
                        <div>{/* Konten lain jika loading tidak aktif */}</div>
                    )}
                </div>
                    
                
                { dataMore.length > 0 ? 
                        <div className="section-header text-center">
                            <h2 className="title" ><span style={{color:'#ff8000'}} >PROGRAM</span><span style={{color:'#0080ff'}}> LAINNYA</span></h2>
                        </div>
                : ''}
                <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
                    { !moreProduk ? ( dataMore.map((row, index) => (
                                                <div className="col" key={index}>
                                                <div className="course-item">
                                                    <div className="course-inner">
                                                        <div className="course-thumb" >
                                                            <img src={`${ process.env.REACT_APP_URL+'assets/foto/'+row.foto}`} alt={`${row.foto}`} style={{objectFit:'contain',height:'auto',minHeight:'280px'}} />
                                                        </div>
                                                        <div className="course-content">
                                                            {row.display !== '1' ? (
                                                                <div className="course-price" style={{minWidth:'25vh',borderRadius:'10px'}}><i className="icofont-calendar"></i>
                                                                    {new Date(JSON.parse(row.tgl_program)[0]).toLocaleDateString('id-ID',{day:'numeric'}) +" - "+new Date(JSON.parse(row.tgl_program)[1]).toLocaleDateString('id-ID',{day:'numeric',month:"long",year:"numeric"}) }
                                                                </div>
                                                            ) : ''}
                                                            {/* <div className="course-category">
                                                                <div style={{backgroundColor:'grey',padding:'5px',marginBottom:'12px',borderRadius:'4px',color:'#ffffff'}}>
                                                                    {row.kategori}
                                                                </div>
                                                            </div> */}
                                                            <Link to={row.display !== '1' ? `/Ourclass/ClassDetail/${encodeURIComponent(AES.encrypt(row?.id_produk.toString(), 'goorbit').toString())}` : ''} ><h4>{row.title}</h4></Link>
                                                            <div className="course-details">
                                                                 { row.display !== '1' ? <div className="couse-count"><i className="icofont-people"></i>{row.jumlah_peserta+` Peserta`}</div> : <div className="couse-count">{HTMLReactParser(row.deskripsi_program)}</div> }
                                                            </div>
                                                            { row.display !== '1' ? <div className="course-footer">
                                                                <div className="course-btn">
                                                                    <Link to={`/Ourclass/ClassDetail/${encodeURIComponent(AES.encrypt(row?.id_produk.toString(), 'goorbit').toString())}`}  className="lab-btn-text">{`ORDER NOW`} <i className="icofont-external-link"></i></Link>
                                                                </div>
                                                            </div> : null  }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                    )) 
                    ) :  '' }
                </div>

            </div>
        </div>
    </div>
    </>
  )
}

export default MostFav