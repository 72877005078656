import  {createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

export const createGuest = (data) =>async (dispatch)=>{
    try {
        await axios.post(process.env.REACT_APP_URL+`guest/create_guest`,qs.stringify(data));
        return;
    } catch (error) {
        console.log(error);
    }
}


export const createGuestProspek = (data) => async(dispatch)=>{
    try {
        await axios.post(process.env.REACT_APP_URL+`guest/create_guest_prospek`,qs.stringify(data));
        return;
    } catch (error) {
        console.log(error);
    }
}


const Guest = createSlice({
    name: 'Guest',
    initialState:{guest:[]},
    reducers:{
        addGuest(state,action){
            // state.guest = action.payload;
        }
    },
})

export const {addGuest} = Guest.actions;

export default Guest;