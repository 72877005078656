import React from 'react'
// import SliderBanner from '../components/SliderBanner'
import Banner from '../components/Banner3';
// import Activity from '../components/Activity'
// import Registry from '../components/Registry'
import FloatingBtn from '../components/FloatingBtn'
import Collaborate from '../components/Collaborate';
import Achivement from '../components/Achivement';
import WhatWeDo from '../components/WhatWeDo';
import Blog from '../components/Blog';

const Home = () => {
  return (
    <>
      <FloatingBtn />
          <Banner />
          {/* <Registry /> */}
          <Blog />
       <WhatWeDo/>
       <Collaborate />
       <Achivement />
    </>
  )
}

export default Home