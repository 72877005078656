import React,{useState, useEffect} from 'react';
import {useDispatch} from 'react-redux'
import { createGuestProspek } from '../store/Guest';

const FloatingConsult = () => {
      const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const buttonStyle ={
        bottom:'20px',
        right: '20px',
        position:'fixed',
        zindex:'10000',
        display:'flex',
        flexDirection:'row',
        maxWidth:'18%',
        borderRadius:'20px',
    }
    
    const mobileButton = isMobile ? {maxWidth:'50%'} : {};


    const guestConsult = async()=>{
      await dispatch(createGuestProspek({email:localStorage.getItem('email_user')}));
      await window.open('https://api.whatsapp.com/send?phone=628115184818','_blank');
      
    }
  return (
    <div>
        <button type="button" style={{
                                ...buttonStyle,...mobileButton
                                }} onClick={guestConsult}>
                                    <i className="icofont-phone-circle" style={{fontSize:'2.5em',marginRight:'5px'}}></i>
                                    <span style={{fontSize:'25px'}}>Konsultasi</span>
                                </button>
    </div>
  )
}

export default FloatingConsult