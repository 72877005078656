import React,{useEffect} from 'react';
import HeaderServices from '../../components/HeaderServices';
// import DetailServices from '../../components/DetailServices';
import FloatingConsult from '../../components/FloatingConsult';

import { useDispatch,useSelector } from 'react-redux';
import { getLayanan } from '../../store/Produk';

const Training = () => {
    
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getLayanan());
    },[dispatch]);
    const layanan = useSelector(state=>state.Produk.layanan);

    const layananSelect = layanan?.find(row=>row.kode === 'training');

  return (
    <>
    <FloatingConsult />
      {/* <Modaldata /> */}
      <HeaderServices data={layananSelect} />
      <div className="course-single-section section-bg" style={{padding:'25px'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                        <h3 className="title" ><span style={{color:'#ff8000'}} >COURSE</span><span style={{color:'#0080ff'}}> OVERVIEW </span></h3>
                                                <div dangerouslySetInnerHTML={{__html:layananSelect?.ringkasan}} ></div>
                                          </div>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                        {/* <div className="col-lg-4">
                            <div className="sidebar-part">
                                <DetailServices />                            
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
    </>
  )
}

export default Training