import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper,SwiperSlide } from "swiper/react";


import { getNews } from '../store/Produk';
import { useDispatch,useSelector } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
// import { useParams } from 'react-router-dom';


// Fungsi untuk membatasi isi_berita

function limitIsiBerita(isi_berita) {
    return isi_berita.length > 100
      ? isi_berita.slice(0, 100) + "..." // Jika panjang isi_berita melebihi batas, potong teks dan tambahkan ellipsis
      : isi_berita; // Jika panjang isi_berita tidak melebihi batas, kembalikan isi_berita asli
  }
  


// const blogList = [
//     {
//         imgUrl: 'assets/images/blog/01.jpg',
//         imgAlt: 'Blog Thumb',
//         title: 'Business Ueporting Rouncil Them Could Plan.',
//         desc: 'Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe',
//         commentCount: '3',
//         btnText: 'Read More',
//         metaList: [
//             {
//                 iconName: 'icofont-ui-user',
//                 text: 'Rajib Raj',
//             },
//             {
//                 iconName: 'icofont-calendar',
//                 text: 'Jun 05,2022',
//             },
//         ],
//     },
//     {
//         imgUrl: 'assets/images/blog/02.jpg',
//         imgAlt: 'Blog Thumb',
//         title: 'Financial Reporting Qouncil What Could More.',
//         desc: 'Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe',
//         commentCount: '3',
//         btnText: 'Read More',
//         metaList: [
//             {
//                 iconName: 'icofont-ui-user',
//                 text: 'Rajib Raj',
//             },
//             {
//                 iconName: 'icofont-calendar',
//                 text: 'Jun 05,2022',
//             },
//         ],
//     },
//     {
//         imgUrl: 'assets/images/blog/03.jpg',
//         imgAlt: 'Blog Thumb',
//         title: 'Consulting Reporting Qounc Arei Could More.',
//         desc: 'Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe',
//         commentCount: '3',
//         btnText: 'Read More',
//         metaList: [
//             {
//                 iconName: 'icofont-ui-user',
//                 text: 'Rajib Raj',
//             },
//             {
//                 iconName: 'icofont-calendar',
//                 text: 'Jun 05,2022',
//             },
//         ],
//     },
// ]


const Blog = () => {

    const dispatch = useDispatch();
    const dataNews = useSelector(state => state.Produk.news);
    
    useEffect(()=>{
        dispatch(getNews());
    },[dispatch]);
    return ( 
        <>
            <div className="blog-section padding-tb section-bg">
                <div className="container">
                    <div className="section-header text-center">
                        <h2 className="title"><span style={{color:'#ff8000'}} >NEWS</span><span style={{color:'#0080ff'}}> EVENT</span></h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row row-cols-1 row-cols-md-12 row-cols-xl-12 justify-content-center g-4">
                            <Swiper
                                    spaceBetween={20}
                                    slidesPerView={3}
                                        loop={'true'}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay]}
                                        breakpoints={{
                                            0: {
                                                width: 0,
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                width: 768,
                                                slidesPerView: 3,
                                            },
                                            1200: {
                                                width: 1200,
                                                slidesPerView: 3,
                                            },
                                        }}
                                    >

                                        {dataNews.map((val, i) => (
                                            <SwiperSlide key={i}>
                                                <div className="col">
                                                    <div className="post-item">
                                                        <div className="post-inner">
                                                            <div className="post-thumb">
                                                                <Link to={`${val.link_video}`}target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_URL+'assets/foto_berita/'+val.foto_berita}`} style={{ height: '30vh', filter: 'blur(0px)' }} alt={`${val.foto_berita}}`} />
                                                                    { val.link_video !== '' && 
                                                                            <span
                                                                            style={{
                                                                            position: 'absolute',
                                                                            top: '25%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',
                                                                            color: '#fff',
                                                                            fontSize: '3rem',
                                                                            zIndex: 1,
                                                                            pointerEvents: 'none',
                                                                            }}
                                                                        >
                                                                            &#9654;
                                                                        </span>
                                                                    }
                                                                </Link>
                                                            </div>
                                                            <div className="post-content">
                                                            <Link to={`/news/${val.id_berita}`}><h4>{val.judul}</h4></Link>
                                                                {HTMLReactParser(limitIsiBerita(val.isi_berita))}
                                                            </div>
                                                            <div className="post-footer">
                                                                <div className="pf-left">
                                                                    <Link to={`/news/${val.id_berita}`} className="lab-btn-text"><span>Read More<i className="icofont-external-link"></i></span></Link>
                                                                </div>
                                                                {/* <div className="pf-right">
                                                                    <i className="icofont-comment"></i>
                                                                    <span className="comment-count">{val.commentCount}</span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                        </div>

                    </div>
                </div>
            </div>
           
        </>
    );
}

export default Blog;