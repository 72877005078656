import React, { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { getFasilitatorSingle } from '../../store/Fasilitator';
import {useParams} from 'react-router-dom';



const FacilitatorSingle = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const fasData = useSelector(state =>state.Fasilitator.fasSingle)

    useEffect(()=>{
        dispatch(getFasilitatorSingle(params.id_fasilitator));
        // console.log(fasData);
    },[dispatch,params]);
    
    

  return (
    <>
            <section className="instructor-single-section padding-tb section-bg">
                <div className="container">
                    <div className="section-header text-center">
                            <span className="subtitle">{"About Facilitator"}</span>
                            <h2 className="title"><span style={{color:'#ff8000'}} >GO ORBIT</span><span style={{color:'#0080ff'}}> FASILITATOR </span></h2>
                        </div>
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <div className="card">
                                    <div className="img1">
                                        <img src={require("../../assets/view.jpg")} alt="instructor" />
                                    </div>
                                    <div className="img2">
                                        <img src={`${process.env.REACT_APP_URL+"assets/foto_fas/"+fasData.foto}`} alt="instructor" />
                                    </div>
                                    <div className="main-text">
                                        <h4 className="title">{fasData.nama}</h4>
                                        <p className="ins-dege">{fasData.spesialisasi}</p>
                                        <span className="ratting">
                                            <i className="icofont-ui-rating"></i>
                                            <i className="icofont-ui-rating"></i>
                                            <i className="icofont-ui-rating"></i>
                                            <i className="icofont-ui-rating"></i>
                                            <i className="icofont-ui-rating"></i>
                                        </span>
                                    <h5 className='mt-3'>About Me</h5>
                                    <div dangerouslySetInnerHTML={{__html:fasData?.deskripsi}} ></div>
                                    </div>
                                </div> 
                            </div>        
                        </div>
                </div>
            </section>
    </>
  )
}

export default FacilitatorSingle