import React, { useEffect } from 'react'
import { getNewsId } from '../../store/Produk';
import { useDispatch,useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';


const NewsDetail = () => {
        const params = useParams();
        const dispatch = useDispatch();
        const dataNews = useSelector(state => state.Produk.newsId);
        useEffect(()=>{
            dispatch(getNewsId(params.id_berita));
        },[dispatch,params]);
  return (
    <>
            <div className="blog-section blog-single padding-tb section-bg">
                <div className="container">
                <div className="section-header text-center">
                            <h2 className="title" ><span style={{color:'#ff8000'}} >DETAIL</span><span style={{color:'#0080ff'}}> NEWS </span></h2>
                        </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-12">
                            <article>
                                <div className="section-wrapper">
                                    <div className="row row-cols-1 justify-content-center g-4">
                                        <div className="col">
                                            <div className="post-item style-2">
                                                <div className="post-inner">
                                                    <div className="post-thumb">
                                                    <Link to={`${dataNews.link_video}`}target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_URL+'assets/foto_berita/'+dataNews.foto_berita}`} style={{ height: '50vh', filter: 'blur(0px)' }} alt={`${dataNews.foto_berita}}`} />
                                                        {dataNews.link_video !== '' &&  
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '70%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    color: '#fff',
                                                                    fontSize: '5rem',
                                                                    zIndex: 1,
                                                                    pointerEvents: 'none',
                                                                    
                                                                }}
                                                                >
                                                                &#9654;
                                                            </span>
                                                        }            
                                                    </Link>
                                                    </div>
                                                    <div className="post-content">    
                                                    <h2>{dataNews.judul}</h2> 
                                                    <div dangerouslySetInnerHTML={{__html:dataNews?.isi_berita}} ></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        
                    </div>
                </div>
            </div>
    </>
  )
}

export default NewsDetail