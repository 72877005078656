import React,{useEffect} from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";
import { useDispatch,useSelector } from "react-redux";
import { getProdukId } from "../../store/Produk";
import { useNavigate, useParams } from "react-router-dom";

import HTMLReactParser from "html-react-parser";
import './format.css'
import { AES, enc } from "crypto-js";
// import Modaldata from "../../components/Modaldata";

const ClassDetail = () => {

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const detail = useSelector(state=>state.Produk.produkId);

  let id_produk = '';

  useEffect(()=>{
    // console.log(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    id_produk = AES.decrypt(params.id_produk,'goorbit').toString(enc.Utf8);
    dispatch(getProdukId(id_produk));
  },[dispatch,params,id_produk,AES]);

  const clickOrder = () =>{
    navigate('/register');
 }


  return (
    <>
        {/* <Modaldata /> */}
        {/* { showModal !== false ? <Modaldata /> : '' }  */}
            <div className="shop-single padding-tb aside-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-12">
                            <article>
                                <div className="product-details">
                                        {detail.map((row,index)=>(
                                          <div className="row" key={index}>
                                            <div className="col-md-5 col-12">
                                              <div className="product-thumb" style={{top:0}}>
                                                  <div className="swiper-container pro-single-top">
                                                      <Swiper
                                                          spaceBetween={30}
                                                          slidesPerView={1}
                                                          loop={'true'}
                                                          autoplay={{
                                                              delay: 5000,
                                                              disableOnInteraction: false,
                                                          }}
                                                          navigation={{
                                                              prevEl: '.pro-single-prev',
                                                              nextEl: '.pro-single-next',
                                                          }}
                                                          modules={[Autoplay, Navigation]}
                                                      >
                                                          <SwiperSlide>
                                                                  <div className="single-thumb">
                                                                      <img src={`${ process.env.REACT_APP_URL+"assets/foto/"+row.foto}`} alt={`${row.foto}`} />
                                                                  </div>
                                                              </SwiperSlide>
                                                      </Swiper>
                                                      {/* <div className="pro-single-next"><i className="icofont-rounded-left"></i></div>
                                                      <div className="pro-single-prev"><i className="icofont-rounded-right"></i></div> */}
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-md-7 col-12">
                                              <div className="post-content">
                                                  <h4>{row.title}</h4>
                                                  <p className="rating">
                                                      <i className="icofont-star"></i>
                                                      <i className="icofont-star"></i>
                                                      <i className="icofont-star"></i>
                                                      <i className="icofont-star"></i>
                                                      <i className="icofont-star"></i>
                                                  </p>
                                                  <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="card bg-danger text-white">
                                                            <div className="card-body">
                                                                <h5 className="card-title text-white">Date</h5>
                                                                <p className="card-text text-white"><i className="icofont-calendar"></i> {new Date(JSON.parse(row?.tgl_program)[0]).toLocaleDateString('id-ID',{day:"numeric"}) + " - " + new Date(JSON.parse(row?.tgl_program)[1]).toLocaleDateString('id-ID',{day:"numeric",month:"long",year:"numeric"}) }</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="card bg-danger text-white">
                                                            <div className="card-body">
                                                                <h5 className="card-title text-white">Time </h5>
                                                                <p className="card-text text-white"><i className="icofont-clock-time"></i> {row?.jam}</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="card bg-danger">
                                                            <div className="card-body">
                                                                <h5 className="card-title text-white">Location</h5>
                                                                <p className="card-text text-white"><i className="icofont-location-pin"></i> {row?.lokasi}</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div className="row mt-4 mb-4">
                                                    <div className="col-md-8">
                                                                <div className="card bg-light">
                                                                <div className="card-body">
                                                                    <h5 className="card-title text-white">Cost</h5>
                                                                    {HTMLReactParser(row?.deskripsi_harga)}
                                                                    {/* <div dangerouslySetInnerHTML={{__html:row?.deskripsi_harga}} ></div> */}
                                                                </div>
                                                                </div>
                                                            </div>
                                                </div>
                                                  {/* <h4>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0,maximumFractionDigits: 0 }).format(row.harga)}</h4> */}
                                                  <h6>{row.title}</h6>
                                                  <span>
                                                        {/* {HTMLReactParser(row?.deskripsi_program)} */}
                                                        <div dangerouslySetInnerHTML={{__html:row?.deskripsi_program}} ></div>
                                                        {/* {cleanHTML(row?.deskripsi_program)} */}
                                                  </span>
                                                  <form>
                                                      <button type="button" onClick={clickOrder} className="lab-btn"><i className="icofont-cart"></i><span>Order Now</span></button>
                                                  </form>
                                              </div>
                                          </div>
                                          </div>
                                        ))}
                                </div>

                            </article>
                        </div>
                        
                    </div>
                </div>
            </div>
    </>
  )
}

export default ClassDetail;