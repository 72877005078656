import React, { useEffect } from 'react'
import {Link} from 'react-router-dom';
import { getNews } from '../../store/Produk';
import { useDispatch,useSelector } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
// import { useParams } from 'react-router-dom';


// Fungsi untuk membatasi isi_berita

function limitIsiBerita(isi_berita) {
    return isi_berita.length > 100
      ? isi_berita.slice(0, 100) + "..." // Jika panjang isi_berita melebihi batas, potong teks dan tambahkan ellipsis
      : isi_berita; // Jika panjang isi_berita tidak melebihi batas, kembalikan isi_berita asli
  }
  

const News = () => {
    const dispatch = useDispatch();
    const dataNews = useSelector(state => state.Produk.news);
    // const dataFil = dataProduk.filter(row => row.jenis === params.jenis );
    
    useEffect(()=>{
        dispatch(getNews());
    },[dispatch]);
    
    return (
        <>
               <div className="blog-section padding-tb section-bg">
                    <div className="container">
                        <div className="section-header text-center">
                            <h2 className="title" ><span style={{color:'#ff8000'}} >GO ORBIT</span><span style={{color:'#0080ff'}}> NEWS </span></h2>
                        </div>
                        <div className="row justify-content-center ">
                            <div className="col-lg-8 col-12">
                                <article>
                                    <div className="section-wrapper">
                                        <div className="row row-cols-1 justify-content-center g-4">

                                            {dataNews.map((val, i) => (
                                                <div className="col" key={i}>
                                                    <div className="post-item style-2">
                                                        <div className="post-inner">
                                                            <div className="post-thumb">
                                                                <Link to="/blog-single"><img src={`${process.env.REACT_APP_URL+'assets/foto_berita/'+val.foto_berita}`} style={{height:'70vh'}} alt={`${val.foto_berita}}`} /></Link>
                                                            </div>
                                                            <div className="post-content">
                                                            <Link to={`/news/${val.id_berita}`}><h3>{val.judul}</h3></Link>
                                                                {/* <div className="meta-post">
                                                                    <ul className="lab-ul">
                                                                        {val.metaList.map((val, i) => (
                                                                            <li key={i}><i className={val.iconName}></i>{val.text}</li>
                                                                        ))}
                                                                    </ul>
                                                                </div> */}
                                                                {HTMLReactParser(limitIsiBerita(val.isi_berita))}
                                                                <Link to={`/news/${val.id_berita}`} className="lab-btn"><span>Read More<i className="icofont-external-link"></i></span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                        {/* <Pagination /> */}
                                    </div>
                                </article>
                            </div>
                            {/* <div className="col-lg-8 col-12">
                                <aside>
                                    <PopularPost />
                                </aside>
                            </div> */}
                        </div>
                    </div>
                </div>
        </>
  )
}

export default News;

